import React from "react";
import { Helmet } from "react-helmet";
import TwitterEmbed from './TwitterEmbed';
import "./style.css";

const LandingPage = () => {
  return (
    <div className="landing-page">
      <Helmet>
        <title>SARDANA COIN</title>
        <meta name="description" content="Sardana Coin Website! Moonshot release 07/30" />
        <meta name="keywords" content="Sardana, Sardana Coin, Sardana.io, coming soon, memecoin, sardanacoin, Cryptocurrency, Blockchain, Decentralized" />
        <meta name="viewport" content="width=device-width, initial-scale=0.2, user-scalable=yes" />
        <link rel="icon" type="image/png" href="/img/sardana-4.png" sizes="32x32" />
        <link rel="icon" type="image/png" href="/img/sardana-4.png" sizes="16x16" />
        <link rel="apple-touch-icon" sizes="180x180" href="/img/sardana-4.png" />
        <link rel="canonical" href="https://sardana.io/" />
        <meta property="og:title" content="SARDANA COIN" />
        <meta property="og:description" content="Sardana Coin Website! Moonshot release 07/30" />
        <meta property="og:image" content="https://sardana.io/img/sardana-4.png" />
        <meta property="og:url" content="https://sardana.io/" />
        <meta property="og:type" content="website" />
      </Helmet>
      <div className="div">
        <div className="overlap-group">
          <div className="rectangle" />
          <img className="designer" alt="Designer" src="/img/designer-39-4.png" />
          <div className="rectangle-2" />
          <div className="rectangle-xz" />
          <div className="rectangle-xa" />
          <div className="rectangle-buy" />
          <div className="rectangle-button" />
          <div className="rectangle-3" />
          <p className="website-under">
          Sardana Coin Website! Moonshot release 07/30
            <br />
            <a href="https://x.com/sardanacoin" target="_blank" rel="noopener noreferrer">x.com</a>
            <br />
            <a href="https://instagram.com/sardanacoin" target="_blank" rel="noopener noreferrer">instagram</a>
            <br />
            <a href="https://t.me/sardanacoin" target="_blank" rel="noopener noreferrer">telegram</a>
          </p>
          <p className="buy-button">
          <a href="https://dexscreener.com/solana/fzecdmwypicxxtyv5klvdn8jhkqxcbudlmd7xn59qa3x" target="_blank" rel="noopener noreferrer">Buy Here!</a>
            </p>
          <div className="text-wrapper">sardana.io</div>
          
          <img className="pie-chart-no-bg" alt="Pie chart no bg" src="/img/ms.png" />
          <img className="fish" alt="Fish" src="/img/fish.png" />
          
          <img className="designer-logo" alt="Designer" src="/img/logo.png" />
          <img className="sardana" alt="Sardana" src="/img/sardana-6.png" />
          <p className="no-tax-mint-revoked">
            no tax!
            <br />
            mint revoked!
            <br />
            token burn!
            <br />
            1.000.000.000 Tokens Emission!
            <br />
            Launch thru Dexscreener Moonshot
          </p>
          <p className="no-tax-mint-revoked-bg">
          no tax!
            <br />
            mint revoked!
            <br />
            token burn!
            <br />
            1.000.000.000 Tokens Emission!
            <br />
            Launch thru Dexscreener Moonshot
          </p>
          <div className="text-wrapper-4">Meet Sardana</div>
          <p className="in-the-vast-digital">
            In the vast digital ocean of blockchain, sardines swam peacefully until giant whales began to dominate,
            devouring the smaller fish with their immense resources. Tired of being prey, a brave group of sardines, led
            by Sardana, decided to take a stand.
            <br />
            Harnessing the power of Solana&#39;s network, these sardines created their own economy with Sardana, a
            decentralized cryptocurrency. Using smart contracts and advanced algorithms, they built a secure and
            transparent network where every sardine had an equal voice.
            <br />
            As news of their resistance spread, other marine communities took notice. Whales, once confident, began to
            worry as sardines organized and grew stronger.
            <br />
            Developers tirelessly built a robust digital infrastructure to support the sardines. Sardana led awareness
            campaigns, inspiring other marine communities to join their cause against the whales&#39; oppression.
            <br />
            As the launch of Sardana approaches, excitement grows... United and ready, the sardines prepare to claim
            their place in the digital ocean, marking the start of a new era of resistance and empowerment for all small
            fish daring to challenge the whales!
          </p>
          <p className="p">The Tale of Sardana: King of the Sardines</p>
          <p className="allocation-the">
            Allocation
            <br />
            1 billion tokens will be emitted. Tokens will be released on dexscreener moonshot and will be listed on t.me/moonshotdx channel 24/7  
          </p>
          
          <p className="allocation-the-bg">
            Allocation
            <br />
            1 billion tokens will be emitted. Tokens will be released on dexscreener moonshot and will be listed on t.me/moonshotdx channel 24/7  
          </p>       
          
          <a
            href="https://medium.com/@team_67394/discover-sardana-unveiling-the-future-of-memecoins-eb809b01f634"
            target="_blank"
            rel="noopener noreferrer"
            className="medium-link"
          >
            <img
              src="/img/medium.png"
              alt="Medium"
              className="medium-img"
            />
          </a>
          <div className="medium-text">Medium Press Release</div>
          <img className="arrow-image" alt="Arrow" src="/img/arrow.png" />
          
          {/* Add the Twitter Embed Component here */}
          <TwitterEmbed />
        </div>
        <div className="overlap">
          <img
            className="DALLE"
            alt="Dalle"
            src="/img/beach.png"
          />
          <div className="rectangle-4" />
          <div className="text-wrapper-6">@TEAM SARDANA 2024</div>
          <div className="text-wrapper-7">
            <a href="https://t.me/sardanacoin" target="_blank" rel="noopener noreferrer">t.me/sardanacoin</a>
          </div>
          <a className="text-wrapper-8" href="https://x.com/sardanacoin" rel="noopener noreferrer" target="_blank">
            x.com/sardanacoin
          </a>
          <div className="text-wrapper-9">social links:</div>
          <img className="sardana-2" alt="Sardana" src="/img/sardana-4.png" />
        </div>
        <p className="RISK-WARNING-trading">
          RISK WARNING: Trading Cryptocurrencies is highly speculative, carries a level of risk and may not be suitable
          for all investors. You may lose some or all of your invested capital, therefore you should not speculate with
          capital that you cannot afford to lose. The content on this site should not be considered investment advice.
          Investing is speculative. When investing your capital is at risk.
          <br />
          We do not allow users of these countries to participate in the promotion: The information on this site is not
          intended for residents of Afghanistan, Benin, Bhutan, China, Crimea region, Cuba, Iran, Iraq, Syria, USA,
          Vatican City, or for use by any person in any country or jurisdiction where such distribution or use would be
          contrary to local law or regulation.
        </p>
      </div>
    </div>
  );
};

export default LandingPage;
