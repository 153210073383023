import React, { useEffect } from 'react';

const TwitterEmbed = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://platform.twitter.com/widgets.js";
    script.async = true;
    script.charset = "utf-8";
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="twitter-container">
      <blockquote className="twitter-tweet">
        <p lang="en" dir="ltr">
          🚀 Important Update on Sardana! 🚀<br /><br />
          We are committed to showing our investors how serious we are about our project and building trust. Therefore, we have locked our tokens on Streamflow Finance. The 35 million Sardana tokens we hold are locked until January 5th, 2025.<br /><br />
          🔒 [Check… <a href="https://t.co/YxMjaTzxtg">pic.twitter.com/YxMjaTzxtg</a>
        </p>
        &mdash; Sardana (@SardanaCoin) <a href="https://twitter.com/SardanaCoin/status/1819055459543748658?ref_src=twsrc%5Etfw">August 1, 2024</a>
      </blockquote>
    </div>
  );
};

export default TwitterEmbed;
